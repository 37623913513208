import { fetchApi, postApi, putApi } from 'api/fetchApi'
import { paths } from 'api/generated/incognito'

type Method = paths['/incognito/{aid}/request']
type FetchIncognitoRequestGet = Method['get']['responses']['200']['schema']

export const fetchIncognitoRequestApi = (userId) => {
  return fetchApi<FetchIncognitoRequestGet>(
    `/incognito/${userId}/request` as keyof paths
  )
}
export const requestIncognitoApi = (userId) => {
  return putApi(`/incognito/${userId}/request` as keyof paths)
}
export const acceptIncognitoApi = (userId) => {
  return postApi(`/incognito/${userId}/request` as keyof paths)
}
