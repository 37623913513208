import { deleteApi, postApi } from 'api/fetchApi'
import { paths } from 'api/generated/messenger_contacts'

export const addFavoriteContactApi = (profileId: number) => {
  return postApi(`/messenger/contact/${profileId}/favorite` as keyof paths)
}

export const removeFavoriteContactApi = (profileId: number) => {
  return deleteApi(`/messenger/contact/${profileId}/favorite` as keyof paths)
}
