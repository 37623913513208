export const enum StopChatDecision {
  ShowStopChatNotice = 'ShowStopChatNotice',
  OpenStoreFrontGift = 'OpenStoreFrontGift',
  OpenStoreFrontVip = 'OpenStoreFrontVip',
}

export const enum ChatEventReasons {
  ChatOpen = 'ChatOpen',
  InputFocused = 'InputFocused',
  /** Общее событие подразумевающее все, что может привести к отправке сообщения */
  Send = 'Send',
}
