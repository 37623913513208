export const milliSecondInSecond = 1000

export const minutes = 1000 * 60
export const hours = 60 * minutes
export const days = 24 * hours

export const resetTimeOnDate = (date: Date) => {
  date.setHours(0, 0, 0, 0)
  return date
}

export const createTomorrowDate = () => {
  const date = new Date()
  // При появлении сообщения на следующей день, оно перестает попадать
  // во временные диапазоны, поэтому добавляю еще один день
  // https://redmine.mamba.ru/issues/107679
  date.setDate(date.getDate() + 2)
  // В дальнейшем надо будет упростить логику поиска диапазонов
  return resetTimeOnDate(date)
}

export const createTodayDate = () => resetTimeOnDate(new Date())

export const createYesterdayDate = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return resetTimeOnDate(date)
}

export const createYearStartDate = () => {
  return new Date(new Date().getFullYear(), 0, 1)
}

export const getCurrentYear = (): number => new Date().getFullYear()

export const convertToDayMonthYear = (date: number): number => {
  return resetTimeOnDate(new Date(date)).getTime()
}

/**
 * Приводит исходную строку с сервера вида '2020-04-22 17:47:52'
 * к виду '2020/04/22 17:47:52', которую может кроссплатформенно распарсить new Date().
 * https://stackoverflow.com/questions/4310953/invalid-date-in-safari
 */
export const fixCrossPlatformDateString = (rawDateString: string | number) => {
  // @ts-expect-error
  // TS не прав, на isNaN можно проверить тип даты с 'Invalid Date'
  if (typeof rawDateString !== 'number' && isNaN(new Date(rawDateString))) {
    // Не всегда нужно заменять '-' на '/', т.к. например, вот такая строка 2021/12/06T11:57:45.590Z
    // в Safari выдаст Invalid Date
    return rawDateString.replace(/-/g, '/')
  }

  return rawDateString
}
