import {
  createTodayDate,
  createTomorrowDate,
  createYearStartDate,
  createYesterdayDate,
  resetTimeOnDate,
} from 'functions/dateTime'
import {
  ChatDateFormat,
  DATE_FORMAT_TYPES,
  messageTypesArray,
  MessengerItemTypes,
} from './Messenger.constants'
import {
  ChatMessage,
  GroupedChatMessage,
  TEXT_CHAIN_STATUS,
} from 'reducers/messenger/ChatMessengerState'

const TOP_GAR_RATIO = 1

export const getCurrentScrollPosition = (listRef: HTMLElement) =>
  listRef.scrollTop + listRef.offsetHeight

export const getScrollEndGap = (listRef: HTMLElement) =>
  listRef.scrollHeight - 100

export const getIsScrollEndGapReached = (listRef: HTMLElement) =>
  getCurrentScrollPosition(listRef) > getScrollEndGap(listRef)

export const getIsScrollTopGapReached = (listRef: HTMLElement) =>
  listRef.scrollTop < TOP_GAR_RATIO * listRef.clientHeight

export const getScrollBottom = (listRef: HTMLElement) => {
  if (listRef) {
    return listRef.scrollHeight - listRef.scrollTop
  }
  return null
}

export const extractLocalTime = (serverDateString: string) => {
  const [hours, minutes] = new Date(serverDateString)
    .toLocaleTimeString()
    .split(':')

  return `${hours}:${minutes}`
}

const createDateAndFormatExtractor = (intervals: TimeInterval[]) => (
  serverDateString: string
) => {
  const messageDate = resetTimeOnDate(new Date(serverDateString))
  const messageDateTimestamp = messageDate.getTime()

  const foundInterval = intervals.find((interval) => {
    return interval.start <= messageDate && messageDate < interval.end
  })
  if (foundInterval) {
    return [messageDateTimestamp, foundInterval.dateFormat]
  } else {
    // Если не попало в даты, берем "сегодня"
    // https://redmine.mamba.ru/issues/107679
    console.error(`Interval not found for ${messageDate}, grab the first one`)
    const [first] = intervals
    return [messageDateTimestamp, first.dateFormat]
  }
}

interface TimeGroup {
  dateTimestamp: number
  dateFormat: ChatDateFormat
  messages: GroupedChatMessage[]
  // ?
}

const createDayGroup = (
  dateTimestamp: number,
  dateFormat: string
): TimeGroup => ({
  dateTimestamp,
  dateFormat: dateFormat as ChatDateFormat,
  messages: [],
})

interface TimeInterval {
  dateFormat: ChatDateFormat
  start: Date
  end: Date
}

interface Accumulator {
  group: TimeGroup | null
  list: TimeGroup[]
}

/**
 * Функция готовит структуру для вьюхи из сырого списка сообщений с сервера.
 * Серверный список отсортирован по дате от свежих сообщений к давним, и никак не сгруппирован.
 *
 * 1. Происходит группировка сообщений по дням и определяется формат вывода дат каждого дня,
 *    относительно текущего времени клиента, а также весь список реверсится.
 *
 * 2. Размечаются цепочки текстовых сообщений для стилевой группировки.
 */
export const prepareMessagesStructure = (
  serverMessagesArray: ChatMessage[]
) => {
  if (!serverMessagesArray) {
    return []
  }

  const tomorrowDate = createTomorrowDate()
  const todayDate = createTodayDate()
  const yesterdayDate = createYesterdayDate()
  const thisYearStartDate = createYearStartDate()

  const todayInterval: TimeInterval = {
    dateFormat: DATE_FORMAT_TYPES.TODAY,
    start: todayDate,
    end: tomorrowDate,
  }

  const yesterdayInterval: TimeInterval = {
    dateFormat: DATE_FORMAT_TYPES.YESTERDAY,
    start: yesterdayDate,
    end: todayDate,
  }

  const thisYearInterval: TimeInterval = {
    dateFormat: DATE_FORMAT_TYPES.DAY_MONTH,
    start: thisYearStartDate,
    end: yesterdayDate,
  }

  const restInterval: TimeInterval = {
    dateFormat: DATE_FORMAT_TYPES.DAY_MONTH_YEAR,
    start: new Date(0),
    end: thisYearStartDate,
  }

  const extractDateAndFormat = createDateAndFormatExtractor([
    todayInterval,
    yesterdayInterval,
    thisYearInterval,
    restInterval,
  ])

  const serverMessageArrayConvertedDate = serverMessagesArray.map((item) => ({
    ...item,
    created: convertDateFromUnixTimeStampToISOString(item.createdTs),
  }))

  const result = serverMessageArrayConvertedDate.reduce<Accumulator>(
    (state, item, index, messages) => {
      if (!messageTypesArray.includes(item.type)) {
        return state
      }

      const [messageDateTimestamp, dateFormat] = extractDateAndFormat(
        item.created
      )

      state.group =
        state.group ||
        createDayGroup(messageDateTimestamp as number, dateFormat as string)
      state.group.messages.push(item as GroupedChatMessage)

      const nextItem = messages[index + 1]
      const nextItemDateTimestamp =
        nextItem !== undefined ? extractDateAndFormat(nextItem.created)[0] : ''

      if (nextItemDateTimestamp !== messageDateTimestamp) {
        state.group.messages.reverse()
        state.group.messages = markChainsInDayGroup(state.group.messages)
        state.list = [state.group, ...state.list]
        state.group = null
      }

      return state
    },
    {
      group: null,
      list: [],
    }
  )
  return result.list
}

const getChainSide = (item: ChatMessage) =>
  item.incoming ? 'incoming' : 'self'

/**
 * Функция размечает цепочки текстовых сообщений в рамках одного дня.
 */
const markChainsInDayGroup = (reversedList: GroupedChatMessage[] = []) => {
  let isTextChainActive = false
  let chainSide = ''

  reversedList.forEach((item, index) => {
    const nextItem = reversedList[index + 1]
    const isNextItemExist = nextItem !== undefined

    if (item.type === MessengerItemTypes.TEXT) {
      if (!isTextChainActive && isNextItemExist) {
        if (
          nextItem.type === MessengerItemTypes.TEXT &&
          getChainSide(item) === getChainSide(nextItem)
        ) {
          isTextChainActive = true
          chainSide = getChainSide(item)
          item.textChain = TEXT_CHAIN_STATUS.START
        }
      } else if (isTextChainActive && isNextItemExist) {
        if (
          nextItem.type === MessengerItemTypes.TEXT &&
          getChainSide(nextItem) === chainSide
        ) {
          item.textChain = TEXT_CHAIN_STATUS.MID
        } else {
          isTextChainActive = false
          item.textChain = TEXT_CHAIN_STATUS.END
        }
      } else if (isTextChainActive && !isNextItemExist) {
        isTextChainActive = false
        item.textChain = TEXT_CHAIN_STATUS.END
      }
    }
  })

  return reversedList
}

/**
 * Мутная функция, нужно переписать
 * @param currentMessages
 * @param chunk
 */
export const tryMergeChunk = (
  currentMessages: ChatMessage[],
  chunk: ChatMessage[] | undefined
) => {
  const serverChatMessages = currentMessages.filter(
    (message) => message.type !== MessengerItemTypes.Failed
  )
  const clientChatMessages = currentMessages.filter(
    (message) => message.type === MessengerItemTypes.Failed
  )
  if (serverChatMessages.length === 0 || chunk?.length === 0) {
    return [...(chunk || []), ...(clientChatMessages || [])]
  }
  const currentStartItem = serverChatMessages[0]
  // console.log({ currentStartItem })
  let mergeIndex: string | null = null

  for (const index in chunk) {
    const item = chunk[index]
    if (item.id === currentStartItem.id) {
      mergeIndex = index
      break
    }
  }

  return mergeIndex === null
    ? null
    : chunk!.slice(0, Number(mergeIndex)).concat(currentMessages)
}

/**
 *
 * @param timeStampNumber
 * from 1293683278 to "2010-12-30T04:27:58.000Z"
 *
 */
export const convertDateFromUnixTimeStampToISOString = (
  timeStampNumber: number
) => new Date(timeStampNumber * 1000).toISOString()

export const createMessageIdName = (itemId: number) => `messageId-${itemId}`
