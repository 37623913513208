import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/messenger_contacts'
import { fetchApi5 } from 'api/index'
import cookie from 'js-cookie'

type MethodReadMark = paths['/messenger/contacts/read']
type BodyReadMark = MethodReadMark['post']['parameters']['body']['body']
type ResultReadMark = MethodReadMark['post']['responses']['204']

export const markReadContactListApi = (contactsIds: number[]) => {
  return postApi<ResultReadMark, BodyReadMark>('/messenger/contacts/read', {
    contactsIds,
  })
}

type MethodUnreadMark = paths['/messenger/contacts/unread']
type BodyUnreadMark = MethodUnreadMark['post']['parameters']['body']['body']
type ResultUnreadMark = MethodUnreadMark['post']['responses']['204']

export const markUnreadContactListApi = (contactsIds: number[]) => {
  return postApi<ResultUnreadMark, BodyUnreadMark>(
    '/messenger/contacts/unread',
    {
      contactsIds,
    }
  )
}

export const moveFromIgnoreContactListApi = (
  contactIds: number[],
  folderId: number,
  locale: string
) => {
  const s_post = cookie.get('s_post')
  return fetchApi5('/contacts/move/', {
    method: 'POST',
    body: JSON.stringify({
      contactIds,
      folderId,
      lang_id: locale,
      s_post,
    }),
  })
}
