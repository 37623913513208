export const convertArrayStringToNumber = (profileIds: string[]) => {
  return profileIds.map((item) => {
    const _item = Number(item)

    if (isNaN(_item)) {
      console.error(
        `some strange in url with profileIds ${item}, profileIds - ${profileIds} is not profileId to delete`
      )

      return 0
    }

    return _item
  })
}
