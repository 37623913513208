import { fetchApi5 } from 'api/index'
import { buildQueryStringFromObject } from 'functions/queryString'

export const fetchMessengerChatApi = (
  profileId: number,
  limit: number,
  offset: number
) =>
  fetchApi5(
    `/users/${profileId}/chat` +
      buildQueryStringFromObject({
        limit: String(limit),
        offset: String(offset),
      })
  )
