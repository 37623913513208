import { postApi } from 'api/fetchApi'
import { operations } from 'api/generated/messenger_support'

type Operation = operations['messenger_support_add_createNewMessage']
type Response = Operation['responses']['200']['schema']
type Body = Operation['parameters']['body']['body']

export const sendMessageToSupportApi = (message: string) => {
  return postApi<Response, Body>('/messenger/support/addMessage', { message })
}
