import { isApi5ChatWithMySelfError } from 'api/index'
import { fetchMessengerSwitchApi } from 'api/chat/fetchMessengerSwitchApi'
import { goBackAction } from 'actions/route/routeAction'
import { AsyncAction } from 'actions/actions.types'
import {
  ChatData,
  ChatMessage,
  MessengerRequestStatus,
} from 'reducers/messenger/ChatMessengerState'
import { hasUserRestrictions } from 'functions/hasUserRestrictions'
import { PhotoRestrictionStatus } from 'components/page/Chat/Messenger/Messenger.constants'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'

const DEFAULT_LIMIT = 50

export const FETCH_MESSENGER_CHAT = 'FETCH_MESSENGER_CHAT' as const

interface FetchMessengerChatAction
  extends AsyncAction<{
    messages: ChatMessage[]
    recipient: ChatData
    // Предупреждение о деликатном содержимом при просмотре изображений в переписке
    photosRestrictionStatus: {
      status: PhotoRestrictionStatus
      noRestrictionForMessagesWithIds: number[]
    } | null
    requestStatus: MessengerRequestStatus | null
  }> {
  type: typeof FETCH_MESSENGER_CHAT
  offset: number
  limit: number
  profileId: string
  contactId: number
  isChangedProfileId: boolean
  loadMoreOld: boolean
  loadMoreNew: boolean
}

export interface FetchMessengerChatParams {
  profileId: number
  isChangedProfileId?: boolean
  offset?: number
  limit?: number
  loadMoreOld?: boolean
  loadMoreNew?: boolean
}

/**
 * Получение и обновление информации о чате.
 * @param profileId
 * @param contactId
 * @param isChangedProfileId
 * @param offset
 * @param limit
 * @param loadMoreOld
 * @param loadMoreNew
 */
export const fetchMessengerChatAction = ({
  profileId,
  isChangedProfileId = false,
  offset = 0,
  limit = DEFAULT_LIMIT,
  loadMoreOld = false,
  loadMoreNew = false,
}: FetchMessengerChatParams) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const state = getState()

  return dispatch({
    type: FETCH_MESSENGER_CHAT,
    promise: async () => {
      const json = await fetchMessengerSwitchApi(
        profileId,
        limit,
        offset,
        hasUserRestrictions(state)
      )
      if (isApi5ChatWithMySelfError(json)) {
        dispatch(goBackAction())
      }

      return json
    },
    profileId: Number(profileId),
    isChangedProfileId,
    offset,
    limit,
    loadMoreOld,
    loadMoreNew,
  })
}

export type ChatMessengerFetchTypes = FetchMessengerChatAction
