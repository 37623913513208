import { fetchApi } from 'api/fetchApi'
import { operations } from 'api/generated/messenger_support'

type Operation = operations['messenger_support_chat_getMessages']
type Response = Operation['responses']['200']['schema']

export const fetchMessengerChatWithSupportApi = (
  limit: number,
  offset: number
) => {
  return fetchApi<Response>(
    `/messenger/support/chat?paging[limit]=${limit}&paging[offset]=${offset}` as '/messenger/support/chat'
  )
}
