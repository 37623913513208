import { postApi } from 'api/fetchApi'
import { operations } from 'api/generated/messenger_support'

type Operation = operations['messenger_support_attach_photos_attachPhotos']
// @ts-ignore
type Response = Operation['responses']['204']['schema']
type Body = Operation['parameters']['body']['body']

export const messengerSupportAttachPhotosApi = (photosIds?: number[]) => {
  return postApi<Response, Body>('/messenger/support/attachPhotos', {
    photoIds: photosIds,
  })
}
